<template>
  <div id="app">
    <b-row class="m-3">
      <b-col md="12" class="p-1">
        <div class="row ">
          <b-col md="3" lg="3" sm="3" v-for="sdg in rsData.sdg " v-bind:key="sdg">
            <sdg-icon :img='sdg.code'></sdg-icon>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row class="m-1">
      <b-col md="12" v-if="data_ke == '0'">
        <div v-for="people in rsData.people" v-bind:key="people.code">
          <people-profile :people=people.name></people-profile>
        </div>
      </b-col>

      <b-col md="12" v-if="data_ke == '1'">
        <div v-for="people in rsData1.people" v-bind:key="people.code">
          <people-profile :people=people.name></people-profile>
        </div>
      </b-col>

      <b-col md="12" v-if="data_ke == '2'">
        <div v-for="people in rsData2.people" v-bind:key="people.code">
          <people-profile :people=people.name></people-profile>
        </div>
      </b-col>
    </b-row>

    <a href="http://lppmitb.wbsantosa.online/" class="no-underlines " target="_blank">
      explore in more detail >>
    </a>
    <br>
  </div>
</template>

<script>


import axios from 'axios'
import PeopleProfile from './components/PeopleProfile.vue'
import SdgIcon from './components/SdgIcon.vue';

export default {
  name: 'App',
  components: {
    SdgIcon, PeopleProfile
  },
  data() {
    return {
      rsData: {
        "people": [
          {
            "name": "Rianto Adhy Sasongko",
            "code": "834"
          },
          {
            "name": "Lia Amelia",
            "code": "1591"
          }
        ],
        "sdg": [
          {
            "name": "SDG4",
            "code": "SDG4"
          },
          {
            "name": "SDG17",
            "code": "SDG17"
          },
          {
            "name": "SDG9",
            "code": "SDG9"
          }
        ]
      },
      rsData1: {
        "people": [
          {
            "name": "Reini Wirahadikusumah",
            "code": "1299"
          },
          {
            "name": "M. Aqus Kariem",
            "code": "749"
          },
          {
            "name": "Mardiyati",
            "code": "971"
          },
          {
            "name": "Raden Dadan Ramdan",
            "code": "1569"
          },
          {
            "name": "Rianto Adhy Sasongko",
            "code": "834"
          },
          {
            "name": "Ridanto Eko Poetro",
            "code": "1073"
          },
          {
            "name": "Mochammad Agoes Moelyadi",
            "code": "543"
          },
          {
            "name": "I Gusti Nyoman Wiratmaja",
            "code": "1146"
          },
          {
            "name": "Muhamad Giri Suada",
            "code": "703"
          },
          {
            "name": "Bambang Kismono Hadi",
            "code": "330"
          },
          {
            "name": "Ignatius Pulung Nurprasetio",
            "code": "1138"
          },
          {
            "name": "Prihadi Setyo Darmanto",
            "code": "1040"
          }
        ],
        "sdg": [
          {
            "name": "SDG4",
            "code": "SDG4"
          },
          {
            "name": "SDG8",
            "code": "SDG8"
          },
          {
            "name": "SDG9",
            "code": "SDG9"
          },
          {
            "name": "SDG17",
            "code": "SDG17"
          },
          {
            "name": "SDG10",
            "code": "SDG10"
          }
        ]
      },
      rsData2: {
        "people": [
          {
            "name": "Yazid bin Yahya",
            "code": "965"
          },
          {
            "name": "Hermawan Judawisastra",
            "code": "265"
          },
          {
            "name": "Ditho Pulungan",
            "code": "85321"
          },
          {
            "name": "Lia Amelia",
            "code": "1591"
          }
        ],
        "sdg": [
          {
            "name": "SDG4",
            "code": "SDG4"
          },
          {
            "name": "SDG9",
            "code": "SDG9"
          },
          {
            "name": "SDG17",
            "code": "SDG17"
          }
        ]
      },
      hits: {},
      url: ''
    }
  },
  watch: {
    url() {
      this.tesLoad();
    }
  },
  mounted() {
    this.url = window.location;
    if (this.url_load == '1') {
      this.url = window.location.href

    } else {
      this.url = 'https://www.itb.ac.id/berita/studi-strategis-dalam-negeri-itb-terima-kunjungan-ppra-66-lemhannas-ri/60760';

    }

    console.log(this.url)

  },
  props: {
    possion: {

    },
    url_load: {

    },
    data_ke: {}
  },

  methods: {
    async tesLoad() {
      const self = this;
      await axios({
        method: 'POST',
        url: 'http://207.148.122.12:10014/api/search/identify',
        data: {
          params: {
            url: self.url
          }
        },
        headers: {
          token: "rah45ia"
        }
      })
        .then(function (response) {
          self.rsData = response.data;

        }).catch(err => {
          self.pesan = err.message;
        });
    },

  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.no-underlines {
  float: left;
  margin-left: 20px;
  ;
  text-decoration: none;
}
</style>
