<template>
  <div style="margin: 10px;">
    <b-card style="text-align: left;">
      <b-row>
        <b-col sm="2">
          <img src="https://lppmitb.wbsantosa.com/sdg/avatar.jpg" width="90%">
        </b-col>
        <b-col sm="9">
          <a :href="'http://lppmitb.wbsantosa.online/profiles_detail/' + queryResult.name.replace(/[ /'?]/g, '_')"
            target="_blank" class="no-underline">
            <h6> {{ queryResult.name }}</h6>
          </a>
          <small class="text-black-50"><small>Group {{ queryResult.group }}</small></small><br>
          <small class="text-black-50"><small>School {{ queryResult.school }}</small></small>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import typesenseClient from "@/config/typesenseService";
export default {

  data() {
    return {
      isHovered: false,
      queryResult: {}
    };
  },
  props: {
    people: {}
  },
  computed: {
    imageSource() {
      return require('@/assets/avatar.jpg');
    }

  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.show = true;
      try {
        const searchResults = await typesenseClient.collections('people').documents().search({
          query_by: 'name',
          q: '',
          //this.query_by
          'filter_by': "name: " + this.people
        });

        this.queryResult = searchResults.hits[0].document;
      } catch (error) {
        this.show = false;
        console.error('Error fetching facets:', error);
      }
    },
  }
}
</script>

<style>
.no-underline {
  text-decoration: none;
}
</style>