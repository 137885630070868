<template>
  <div>
    <img :src="'https://lppmitb.wbsantosa.com/sdg/' + img + '.svg'" aria-expanded="true"
      style="margin-right: -20px;margin-bottom: 5px;;">
  </div>
</template>

<script>

export default {
  data() {
    return {
      isHovered: false
    };
  },
  props: {
    img: {}
  },
  computed: {
    imageSource() {
      return require(`@/assets/sdg/${this.img}.svg`);
    }
  },
  methods: {

  }

}
</script>

<style></style>