
import { Client }  from 'typesense'; 
  
const typesenseClient = new Client({
    nodes: [{
            host:'jg3hp2inlfqboeyup-1.a1.typesense.net',
            port: '',
            protocol: 'https' // or 'https'
          }],
  apiKey: 'eFv4O5mzDrifH3wKybLbf7Jx9he6xYHu'
});
console.log('ok'+ localStorage.getItem('api-key'))
export default typesenseClient;